<template>
	<div class="reports">
		<v-card
			:class="getReportClass(report)"
			v-for="report in reports"
			:key="report._id"
			color="background"
		>
			<v-card-title width="100%" class="pb-3">
				<v-avatar size="32" class="mr-3">
					<v-img :src="getUserAvatarUrl(report.by)" alt="avatar" />
				</v-avatar>
				<span> {{ report.by.username }}#{{ report.by.discriminator }} </span>
				<v-spacer></v-spacer>
				<AdminUserCommentActionButton
					v-if="!report.action"
					class="mr-2"
					:user="getReportUser(report.by)"
					:report="report"
					@refresh="$emit('refresh')"
				/>
				<AdminUserReportModal :user="getReportUser(report.by)" />
			</v-card-title>
			<div
				class="comments"
				v-for="comment in getComments(report)"
				:key="comment._id"
			>
				<div
					class="date-divider"
					:class="comment.action ? 'action-comment' : ''"
				>
					<div class="text-caption date">
						{{ getDate(comment.createdAt) }}
					</div>
					<div class="text-caption mx-2 date">•</div>
					<div class="text-caption comment-type">
						{{ getTag(report, comment) }}
					</div>
					<v-divider class="divider"></v-divider>
				</div>
				<div class="comment">
					<v-card-text class="text">
						<span v-html="cleanComment(comment.text)"></span>
					</v-card-text>
					<div class="images">
						<v-img
							class="image"
							v-for="(image, imageIndex) in comment.images"
							:key="imageIndex"
							:src="
								getImageUrl(report._id, comment.commentIndex, imageIndex, image)
							"
							width="80"
							height="80"
							contain
							v-ripple
							@click="
								redirect(
									getImageUrl(
										report._id,
										comment.commentIndex,
										imageIndex,
										image
									)
								)
							"
						>
						</v-img>
					</div>
					<v-btn
						v-if="comment.guildInvite"
						target="_blank"
						color="green"
						class="invite-button"
						@click="openTab(comment.guildInvite)"
					>
						{{ comment.guildInvite }}
						<v-icon small class="ml-2">mdi-open-in-new</v-icon>
					</v-btn>
				</div>
			</div>
		</v-card>
	</div>
</template>

<script>
export default {
	name: 'AdminUserReportInfoComments',
	components: {
		AdminUserReportModal: () =>
			import('@/views/admin/components/AdminUserReportModal'),
		AdminUserCommentActionButton: () =>
			import('@/views/admin/components/AdminUserCommentActionButton'),
	},
	props: {
		reports: {
			type: Array,
		},
	},
	data: () => ({}),
	methods: {
		openTab(link) {
			if (link.includes('http')) {
				window.open(link, '_blank');
			} else {
				window.open(`https://${link}`, '_blank');
			}
		},
		getReportClass(report) {
			let reportClass = 'report ';
			reportClass += report.action ? 'action' : '';
			reportClass += report.ignored ? 'ignored' : '';
			return reportClass;
		},
		getComments(report) {
			let comments = [];
			if (report.comments) {
				comments = comments.concat(report.comments);
				for (let i in comments) {
					comments[i].commentIndex = i;
				}
			}
			if (report.adminComments) {
				comments = comments.concat(report.adminComments);
			}

			return comments.sort(
				(a, b) => new Date(b.createdAt) - new Date(a.createdAt)
			);
		},
		cleanComment(comment) {
			return comment
				.replaceAll('\n', '<br />')
				.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
				.replace(/__(.*?)__/g, '<u>$1</u>')
				.replace(/~~(.*?)~~/g, '<i>$1</i>')
				.replace(/--(.*?)--/g, '<del>$1</del>')
				.replace(/<<(.*?)>>/g, "<a href='$1'>Link</a>");
		},
		getTag(report, comment) {
			if (comment.selections) {
				return comment.selections[comment.selections.length - 1];
			}
			if (report.action) {
				return report.action;
			}
			if (comment.action) {
				return comment.action;
			}
			return 'unknown';
		},
		redirect(url) {
			window.open(url);
		},
		getImageUrl(id, commentIndex, imageIndex, image) {
			return `${process.env.VUE_APP_CDN}/cdn/report/${id}/${commentIndex}/${imageIndex}/evidence.${image.ext}`;
		},
		avatarUrl(guild) {
			return `https://cdn.discordapp.com/icons/${guild._id}/${guild.avatar}.png`;
		},
		getUserAvatarUrl({ discordId, discriminator, avatar }) {
			if (avatar) {
				return `https://cdn.discordapp.com/avatars/${discordId}/${avatar}.png`;
			} else {
				return `https://cdn.discordapp.com/embed/avatars/${
					parseInt(discriminator) % 5
				}.png`;
			}
		},
		getDate(date) {
			date = new Date(date);
			let hours = date.getHours();
			let minutes = date.getMinutes();
			let ampm = hours >= 12 ? 'pm' : 'am';
			hours = hours % 12;
			hours = hours ? hours : 12; // the hour '0' should be '12'
			minutes = minutes < 10 ? '0' + minutes : minutes;
			let strTime = hours + ':' + minutes + ' ' + ampm;
			return (
				date.getMonth() +
				1 +
				'/' +
				date.getDate() +
				'/' +
				date.getFullYear() +
				'  ' +
				strTime
			);
		},
		getReportUser(user) {
			return {
				id: user.discordId,
				avatar: user.avatar,
				avatarUrl: this.getUserAvatarUrl(user),
				discriminator: user.discriminator,
				username: user.username,
				fullUsername: `${user.username}#${user.discriminator}`,
				reports: user.reportsAgainstCount,
			};
		},
	},
};
</script>

<style scoped>
.reports {
	display: flex;
	flex-direction: column;
}

.report {
	padding: 10px;
}

.divider {
	margin-top: 10px;
	margin-left: 10px;
}

.date-divider {
	display: flex;
	padding: 0px 16px;
}

.date {
	color: rgba(255, 255, 255, 0.4);
}

.comment-type {
	color: rgba(255, 255, 255, 0.4);
	font-weight: 600 !important;
}

.images {
	display: flex;
	gap: 10px;
	margin-left: 10px;
}

.image {
	max-width: 80px;
	max-height: 80px;
	cursor: pointer;
}

.text {
	padding-bottom: 0px;
	padding-top: 0px;
	padding-left: 10px;
}

.comment {
	border-left: solid 1px rgba(255, 255, 255, 0.12);
	margin-left: 20px;
	padding-bottom: 20px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.comments {
}

.action {
	border: 2px solid var(--v-warning-base) !important;
}

.action-comment > div {
	color: var(--v-secondary-base);
}

.action-comment > hr {
	border-color: var(--v-secondary-base);
}

.ignored {
	opacity: 0.3;
}

.invite-button {
	margin-left: 10px;
	margin-top: 5px;
}
</style>
