var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reports"},_vm._l((_vm.reports),function(report){return _c('v-card',{key:report._id,class:_vm.getReportClass(report),attrs:{"color":"background"}},[_c('v-card-title',{staticClass:"pb-3",attrs:{"width":"100%"}},[_c('v-avatar',{staticClass:"mr-3",attrs:{"size":"32"}},[_c('v-img',{attrs:{"src":_vm.getUserAvatarUrl(report.by),"alt":"avatar"}})],1),_c('span',[_vm._v(" "+_vm._s(report.by.username)+"#"+_vm._s(report.by.discriminator)+" ")]),_c('v-spacer'),(!report.action)?_c('AdminUserCommentActionButton',{staticClass:"mr-2",attrs:{"user":_vm.getReportUser(report.by),"report":report},on:{"refresh":function($event){return _vm.$emit('refresh')}}}):_vm._e(),_c('AdminUserReportModal',{attrs:{"user":_vm.getReportUser(report.by)}})],1),_vm._l((_vm.getComments(report)),function(comment){return _c('div',{key:comment._id,staticClass:"comments"},[_c('div',{staticClass:"date-divider",class:comment.action ? 'action-comment' : ''},[_c('div',{staticClass:"text-caption date"},[_vm._v(" "+_vm._s(_vm.getDate(comment.createdAt))+" ")]),_c('div',{staticClass:"text-caption mx-2 date"},[_vm._v("•")]),_c('div',{staticClass:"text-caption comment-type"},[_vm._v(" "+_vm._s(_vm.getTag(report, comment))+" ")]),_c('v-divider',{staticClass:"divider"})],1),_c('div',{staticClass:"comment"},[_c('v-card-text',{staticClass:"text"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.cleanComment(comment.text))}})]),_c('div',{staticClass:"images"},_vm._l((comment.images),function(image,imageIndex){return _c('v-img',{directives:[{name:"ripple",rawName:"v-ripple"}],key:imageIndex,staticClass:"image",attrs:{"src":_vm.getImageUrl(report._id, comment.commentIndex, imageIndex, image),"width":"80","height":"80","contain":""},on:{"click":function($event){_vm.redirect(
								_vm.getImageUrl(
									report._id,
									comment.commentIndex,
									imageIndex,
									image
								)
							)}}})}),1),(comment.guildInvite)?_c('v-btn',{staticClass:"invite-button",attrs:{"target":"_blank","color":"green"},on:{"click":function($event){return _vm.openTab(comment.guildInvite)}}},[_vm._v(" "+_vm._s(comment.guildInvite)+" "),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("mdi-open-in-new")])],1):_vm._e()],1)])})],2)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }