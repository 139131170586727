<template>
	<v-container>
		<v-row v-for="item in data" :key="item.key">
			<v-col>
				{{ item.key }}
			</v-col>
			<v-col>
				{{ item.value }}
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: 'AdminUserReportInfoTable',
	props: {
		data: {
			type: Array,
		},
	},
};
</script>

<style scoped>
.page {
	padding: 20px;
}
</style>
